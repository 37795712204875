import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './styles/global.scss'
import i18n from './i18n'
import 'izitoast/dist/css/iziToast.min.css'
import Notifications from './plugins/notifications'
import GenericServices from './plugins/generics'
import NProgress from 'vue-nprogress'
import VueNumericInput from 'vue-numeric-input'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocketIOExt from 'vue-socket.io-extended'
import './serviceWorker/registerServiceWorker'
import OneSignalVue from 'onesignal-vue'
import { io } from 'socket.io-client'
import handleTokenFromURL from './services/tokenFromUrl.js'
Vue.use(OneSignalVue)
const socket = io()
Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket, { store })

Vue.use(VueNumericInput)
Vue.use(Notifications)
Vue.use(GenericServices)
Vue.use(NProgress)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
  },
})

const nprogress = new NProgress()
const init = async () => {
  //checks user auth status
  await handleTokenFromURL()
  try {
    store.commit('initialiseStore')
    store.dispatch('offline/listen')

    await store.dispatch(`auth/checkLogin`, {
      toastOptions: { disabled: true },
    })
  } catch {
    console.log('No auth')
    store.commit('clearData')
  } finally {
    store.$vue = new Vue({
      nprogress,
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
      beforeMount() {
        this.$OneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
          safari_web_id: `web.onesignal.auto.${process.env.VUE_APP_ONESIGNAL_APP_ID}`,
          notifyButton: {
            enable: true,
          },
          subdomainName: 'zahi',
          // allowLocalhostAsSecureOrigin: true,
        })
      },
    }).$mount('#app')
  }
}

init()
